const isProduction = process.env.NODE_ENV === 'production';
const prodFeUrl = 'https://utr-ops-tracker-fe.azurewebsites.net'
const prodBeUrl = 'https://utr-ops-tracker.azurewebsites.net'

const config = {
  backendUrl: isProduction
    ? prodBeUrl
    : 'http://localhost:5000',

  redirectUri: isProduction
    ? `${prodFeUrl}/landing`
    : 'http://localhost:3000/landing',

  postLogoutRedirectUri: isProduction
    ? prodFeUrl
    : 'http://localhost:3000',
};

export default config;
