import React from "react";
import { Link } from "react-router-dom";

interface LandingPageProps {
  user: { name: string };
}

const LandingPage: React.FC<LandingPageProps> = ({ user }) => (
  <div style={{ textAlign: "center", marginTop: "100px" }}>
    <h2>Welcome, {user.name}!</h2>
    <p>You are now logged in with your Azure credentials.</p>

    <Link to="/tracker">
      <button style={{ marginTop: "20px", padding: "10px 20px" }}>
        Go to Deployment Tracker
      </button>
    </Link>
  </div>
);

export default LandingPage
