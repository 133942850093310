import config from './config';

export const msalConfig = {
  auth: {
    clientId: "736513a8-bf48-454c-996c-04f3c5b8a173",
    authority: "https://login.microsoftonline.com/033cff7f-be94-4a98-b343-03b8cd2f82c4",
    redirectUri: config.redirectUri,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: 'localStorage', // or 'sessionStorage'
    storeAuthStateInCookie: true, // Recommended for IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["User.Read", "api://736513a8-bf48-454c-996c-04f3c5b8a173/access_as_user"],
};
