import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import config from "./config";
import { PublicClientApplication, AccountInfo } from "@azure/msal-browser";
import Login from "./components/Login";
import LandingPage from "./components/LandingPage";
import DeploymentTracker from "./components/DeploymentTracker";
import TopBar from "./components/TopBar"; // Import the new TopBar component

// Create and initialize the MSAL instance
const pca = new PublicClientApplication(msalConfig);

const App: React.FC = () => {
  const [user, setUser] = useState<AccountInfo | null>(null);
  const [isReady, setIsReady] = useState(false); // Track MSAL readiness

  // Initialize MSAL and handle redirect promise
  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await pca.initialize();
        const response = await pca.handleRedirectPromise();

        if (response) {
          pca.setActiveAccount(response.account);
          setUser(response.account || null);
        } else {
          setUser(pca.getActiveAccount());
        }
      } catch (error) {
        console.error("Error initializing MSAL:", error);
      } finally {
        setIsReady(true);
      }
    };

    initializeMsal();
  }, []);

  const handleLogout = () => {
    pca.logoutRedirect({ postLogoutRedirectUri: config.postLogoutRedirectUri });
  };

  if (!isReady) return <p>Loading...</p>;

  return (
    <MsalProvider instance={pca}>
      <Router>
        {/* Render TopBar only if the user is logged in */}
        {user && <TopBar user={user} onLogout={handleLogout} />}
        <Routes>
          <Route
            path="/"
            element={user ? <Navigate to="/landing" /> : <Login onLoginSuccess={setUser} />}
          />
          <Route path="/landing" element={<LandingPage user={{ name: user?.name || "User" }} />} />
          <Route path="/tracker" element={<DeploymentTracker />} />
        </Routes>
      </Router>
    </MsalProvider>
  );
};

export default App;
