import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import config from '../config';
import './DeploymentTracker.css';

interface Deployment {
  environment: string;
  engineer_name: string;
  branch_name: string;
  logged_at: string;
  pipeline_name: string;
  deployment_status: string;
  build_uri: string;
  repository_uri: string;
}

const environments = [
  'test1', 'test2', 'test3', 'test4', 'test5', 'test6',
  'test7', 'test8', 'test9', 'test10', 'test11', 'test12',
  'qa', 'prod',
];

const DeploymentTracker: React.FC = () => {
  const { instance, accounts } = useMsal();
  const [deployments, setDeployments] = useState<Record<string, Deployment[]>>({});
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (accounts.length === 0) {
      instance.loginRedirect();
    }
  }, [accounts, instance]);

  useEffect(() => {
    const fetchDeployments = async () => {
      try {
        const account = accounts[0];

        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ['api://736513a8-bf48-454c-996c-04f3c5b8a173/access_as_user'],
          account,
        });

        const response = await fetch(`${config.backendUrl}/deployments`, {
          headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`,
          },
        });

        const data: Deployment[] = await response.json();

        const environmentDeployments: Record<string, Deployment[]> = environments.reduce(
          (acc, env) => {
            acc[env] = data.filter(deployment => deployment.environment === env);
            return acc;
          },
          {} as Record<string, Deployment[]>
        );

        setDeployments(environmentDeployments);
      } catch (error) {
        console.error('Error fetching deployments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDeployments();
  }, [accounts, instance]);

  const toggleExpand = (env: string) => {
    setExpanded(prev => ({ ...prev, [env]: !prev[env] }));
  };

  if (loading) {
    return <p>Loading deployments...</p>;
  }

  return (
    <div className="container">
      <h1>Deployments</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Environment</th>
            <th>Engineer Name</th>
            <th>Branch Name</th>
            <th>Timestamp</th>
            <th>Pipeline Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {environments.map(env => {
            const deploymentsForEnv = deployments[env] || [];

            return (
              <React.Fragment key={env}>
                <tr onClick={() => toggleExpand(env)} style={{ cursor: 'pointer' }}>
                  <td colSpan={6}>
                    {env} {expanded[env] ? '▼' : '▶'}
                  </td>
                </tr>
                {expanded[env] &&
                  deploymentsForEnv.map((deployment, index) => (
                    <tr key={`${env}-${index}`}>
                      <td>{`${env}`}</td>
                      <td>{deployment.engineer_name}</td>
                      <td>
                        <a
                          href={`${deployment.repository_uri}/src/${deployment.branch_name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {deployment.branch_name}
                        </a>
                      </td>
                      <td>{new Date(deployment.logged_at).toLocaleString()}</td>
                      <td>
                        <a
                          href={deployment.build_uri}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {deployment.pipeline_name}
                        </a>
                      </td>
                      <td>{deployment.deployment_status || 'N/A'}</td>
                    </tr>
                  ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DeploymentTracker;
