import React from "react";
import { useMsal } from "@azure/msal-react";

const Login: React.FC<{ onLoginSuccess: (account: any) => void }> = ({ onLoginSuccess }) => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup().then((response) => {
      console.log("User Info:", response.account);
      onLoginSuccess(response.account);
    }).catch((error) => {
      console.error("Login failed", error);
    });
  };

  return (
    <div style={{ textAlign: "center", marginTop: "100px" }}>
      <h2>Welcome to Deployment Tracker</h2>
      <button onClick={handleLogin}>Login with Azure</button>
    </div>
  );
};

export default Login;
