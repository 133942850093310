import React from "react";
import { Link } from "react-router-dom";
import { AccountInfo } from "@azure/msal-browser";
import './TopBar.css'; // Optional: Add styles if needed

interface TopBarProps {
  user: AccountInfo | null;
  onLogout: () => void;
}

const TopBar: React.FC<TopBarProps> = ({ user, onLogout }) => (
  <header className="top-bar">
    <Link to="/landing" className="top-bar__title">
      <h1>Ops Tracker</h1>
    </Link>
    {user && (
      <div className="top-bar__user-info">
        <span className="top-bar__welcome">Welcome, {user.name || "User"}</span>
        <button className="top-bar__logout-button" onClick={onLogout}>
          Logout
        </button>
      </div>
    )}
  </header>
);

export default TopBar;
